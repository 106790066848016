import PropTypes from 'prop-types';
import React from 'react';

import { Wrapper } from './PageMargins.style';

/**
 * Page margins wth max width setting.
 *
 * Intended to deliver same functionality as previously using:
 * ```html
 * <div class="wrapper" />
 *   <div class="container-fluid">
 *   </div>
 * </div>
 * ```
 */
const PageMargins = React.forwardRef(({ children, className, style }, ref) => (
  <Wrapper className={className} style={style} ref={ref}>
    {children}
  </Wrapper>
));

PageMargins.displayName = 'PageMargins';

PageMargins.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
    PropTypes.element,
  ]).isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
};

PageMargins.defaultProps = {
  style: {},
  className: '',
};

export default PageMargins;
